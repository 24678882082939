import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = () => {

    return (
        <div className="load-screen">
            <div className="global-loader">
                <Spinner color='primary' style={{ width: '5rem', height: '5rem' }}>
                    Cargando...
                </Spinner>
                <p>Cargando...</p>
            </div>
        </div>
    )
}

export default Loader