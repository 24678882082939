// -- React and related libs
import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { HashRouter } from "react-router-dom";
// -- Redux
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// -- Custom Components
// import { Login } from "./pages/login/Login";
import Login from "./pages/login/Login";

// -- Third Party Libs
import { ToastContainer } from "react-toastify";

// -- Component Styles
import "./styles/app.scss";
import "./styles/fonts.css"

import Demo from "./pages/Demo/Demo";

//azure

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from "./components/Layout/Layout";


const App = (props) => {


  //https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#handleredirectpromise
  /*
  .then is invoked and tokenResponse is truthy: The application is returning from a redirect operation that was successful.
  .then is invoked and tokenResponse is falsy (null): The application isn't returning from a redirect operation.
  .catch is invoked: The application is returning from a redirect operation and there was an error.
   */
  // instance.handleRedirectPromise()
  //   .then((tokenResponse) => {
  //     console.log("Redirect promise")
  //     console.log(tokenResponse)
  //     //handle redirect response
  //     if (tokenResponse) {
  //       localStorage.setItem(API_ACCESS_TOKEN_KEY, tokenResponse.accessToken)
  //       registraTransaccionLogin();

  //     }

  //   })
  //   .catch((error) => {
  //     //handle redirect error
  //   })




  return (
    <div>
      <AuthenticatedTemplate>
        <ToastContainer />
        <HashRouter>
          <Layout />
        </HashRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <ToastContainer />
        <HashRouter>
          <Switch>
            {/* <Route path="/" exact render={() => <Redirect to="/login" />} />
            <Route path="/login" exact component={Login} />
            <Route path="/error" exact component={ErrorPage} /> */}
            <Route path="/login" exact component={Login} />
            <Route path="/Demo" exact component={Demo} />
            <Route path='*' exact={true} render={() => <Redirect to="/login" />} />
          </Switch>
        </HashRouter>
      </UnauthenticatedTemplate>
    </div>
  );
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(App);
