import { FormGroup, FormText, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { isLoading } from "../../actions/actions";
import { CambiarContraseña } from '../../services/userService';
import { useEffect } from 'react';
import s from "./ChangePassword.module.scss";
const ModalChangePassword = ({ show, setShow, handleClose }) => {

    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const dispatch = useDispatch();

    useEffect(() => {
        reset()
    }, [show])

    const onSubmit = async (data) => {
        try {
            dispatch(isLoading(true))
            const resultado = await CambiarContraseña({
                currentPassword: data.contraseñaActual,
                newPassword: data.contraseñaNueva
            });
            console.log(resultado)

            if (resultado) {
                if (resultado.fueCorrecto) {
                    toast.success(resultado.mensaje)
                } else {
                    toast.error(resultado.mensaje)
                }

            } else {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }

            const timer = setTimeout(() => {
                handleClose()
                dispatch(isLoading(false))
            }, 2000);
            return () => clearTimeout(timer)

        }
        catch (exception) {
            console.log(exception)
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }

    const ValidaNuevaPass = (value) => {
        if (!value)
            return "Este campo es requerido";

        if (value.length <= 8) {
            return "La contraseña debe tener al menos 8 caracteres";
        }
        if (!/(?=.*[A-Z])/.test(value)) {
            return "La contraseña debe contener al menos una letra mayúscula";

        }
        if (!/(?=.*\d)/.test(value)) {
            return "La contraseña debe contener al menos un número";
        }
        return true;
    }

    const ValidaNuevaPassReingresada = (value) => {
        if (!value)
            return "Este campo es requerido";

        const contraseñaNueva = getValues('contraseñaNueva')
        if (contraseñaNueva && contraseñaNueva !== value)
            return "Las contraseñas no coinciden";

        return true;
    }

    return (
        <Modal isOpen={show} toggle={handleClose} size='md' centered={true}>
            <ModalHeader toggle={handleClose}>Cambio de contraseña</ModalHeader>
            <ModalBody>
                <Row className={s.password_requirements} >
                    <Col xs={12} >
                        Consideraciones
                    </Col>
                    <Col xs={12} >
                        <ul>
                            <li>Largo mínimo 8 caracteres.</li>
                            <li>Al menos 1 mayúscula.</li>
                            <li>Al menos 1 número.</li>
                            <li>No puede ser una de las 5 últimas contraseñas utilizadas.</li>
                            <li>No puede cambiarse más de una vez al día.</li>

                        </ul>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup className="my-3">
                                <FormText>Contraseña actual</FormText>
                                <input
                                    className="input-transparent pl-3 form-control"
                                    {...register("contraseñaActual", {
                                        required: true,
                                        maxLength: 50
                                    })}
                                    type="password"
                                />
                                {errors?.contraseñaActual && errors?.contraseñaActual.type === "required" && <span className="valid">Este campo es requerido.</span>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <FormGroup className="my-3">
                                <FormText>Nueva Contraseña</FormText>
                                <input
                                    className="input-transparent pl-3 form-control"
                                    {...register("contraseñaNueva", {
                                        validate: (value) => ValidaNuevaPass(value),
                                    })}
                                    type="password"
                                />
                                {errors?.contraseñaNueva && <span className="valid">{errors.contraseñaNueva.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <FormGroup className="my-3">
                                <FormText>Reingrese nueva contraseña</FormText>
                                <input
                                    className="input-transparent pl-3 form-control"
                                    {...register("contraseñaNuevaVerificacion", {
                                        validate: (value) => ValidaNuevaPassReingresada(value)
                                    })}
                                    type="password"
                                />
                                {errors?.contraseñaNuevaVerificacion && <span className="valid">{errors?.contraseñaNuevaVerificacion.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12} >
                            <Button
                                color="primary"
                                type="submit"
                            >Confirmar cambios</Button>
                        </Col>

                    </Row>
                </form>

            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setShow(false)}>Cerrar</Button>{' '}
            </ModalFooter>
        </Modal>
    );
}

export default ModalChangePassword