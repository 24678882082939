export class AccionesEnum {
    static LOGIN = new AccionesEnum(1);
    static VER_REPORTE = new AccionesEnum(2);
    static VER_REPORTE_NO_CONTRATADO = new AccionesEnum(3);


    constructor(value) {
        this.value = value;
    }
    toString() {
        return `IdAccion.${this.value}`;
    }
}