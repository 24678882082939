import config from './configAxios'
import configAxiosAnon from './configAxiosAnon'

const urlApi = process.env.REACT_APP_URLAPI
const axios = config.iAxios;
const axiosAnon = configAxiosAnon.iAxios;

const getInfoUsuario = async () => {
    return axios.get(`${urlApi}/api/Usuario/Informacion`).then((res) => { return res.data })
}

const getReporte = async (idReporte) => {
    return axios.get(`${urlApi}/api/Reporte/getReporte/${idReporte}`).then((res) => { return res.data })
}
const getManualReporte = async (idReporte) => {
    return axios.get(`${urlApi}/api/Reporte/${idReporte}/manual`, { responseType: "blob" })//retornamos todo el response en este caso
}
const guardarTransaccion = async (transaccion) => {
    return axios.post(`${urlApi}/api/Transaccion`, transaccion).then((res) => { return res.data })
}

const getReportesNoContratados = async () => {
    return axios.get(`${urlApi}/api/Usuario/NoContratado`).then((res) => { return res.data })
}

const getReporteDemo = async (captchaToken) => {
    return axiosAnon.get(`${urlApi}/api/PowerBi/getDemo/${captchaToken}`).then((res) => { return res.data })
}
const backendApi = {
    getInfoUsuario,
    guardarTransaccion,
    getReporte,
    getReportesNoContratados,
    getReporteDemo,
    getManualReporte
}

export default backendApi