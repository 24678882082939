
import api from '../api/api'
import { toast } from 'react-toastify'

export const SET_ID_REPORTE = 'SET_ID_REPORTE';
export const SET_INFO_USUARIO = 'SET_INFO_USUARIO';
export const SET_IS_LOADING = 'SET_IS_LOADING';


export const setIsLoading = (payload) => {
    return {
        type: SET_IS_LOADING,
        payload,
    }
}

const registraIdReporte = (payload) => {
    return {
        type: SET_ID_REPORTE,
        payload,
    }
}

export const setInfoUsuario = (payload) => {
    return {
        type: SET_INFO_USUARIO,
        payload,
    }
}

export const setIdReporte = (id) => dispatch => {
    dispatch(registraIdReporte(id));
}

export const getInfoUsuario = () => async dispatch => {
    try {
        dispatch(setIsLoading(true))
        const infoUsuario = await api.getInfoUsuario()

        dispatch(setInfoUsuario(infoUsuario));
    }
    catch (error) {
        toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        console.log(error)
    }
    finally {
        dispatch(setIsLoading(false))
    }
}

export const isLoading = (estaCargando) => async dispatch => {
    try {
        dispatch(setIsLoading(estaCargando));
    }
    catch (error) {
        console.log(error)
    }
}