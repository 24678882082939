import axios from 'axios'
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from '../config/authConfig';

const pca = new PublicClientApplication(msalConfig);


let iAxios = axios.create({
    defaultInterceptors: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

const getToken = async () => {
    const accounts = pca.getAllAccounts();
    let token = ''
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    await pca.acquireTokenSilent(request).then((response) => {
        token = response.accessToken
    }).catch((e) => {
        console.log(e)
        console.log(request)
        console.log("Falla al obtener token")
        pca.logoutRedirect() (accounts[0]).catch(e => {
            console.error(e);
        });
        // pca.acquireTokenRedirect(request).catch(e => {
        //     console.error(e);
        // });

    });
    return token
}

iAxios.interceptors.request.use(
    async (config) => {
        try {
            let tokenJWT = await getToken()
            config.headers.common['Authorization'] = `Bearer ${tokenJWT}`
        }
        catch (error) {
            console.log("Auth error request")
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

iAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.log("Auth error response")
        // if (error.request.status === 401) {
        //     console.log("Intentando generar autenticacion nuevamente")
        //     const accounts = pca.getAllAccounts();
        //     const request = {
        //         ...loginRequest,
        //         account: accounts[0]
        //     };
        //     pca.acquireTokenRedirect(request).catch(e => {
        //         console.error(e);
        //     });

        // }

        return Promise.reject(error)

    }
)

const config = { iAxios }

export default config