import React from "react";
import { Navbar } from "reactstrap";
import s from "./Header.module.scss";
import BolsaComercioLogo from "../Icons/BolsaComercioLogo";
import l from "../Sidebar/Sidebar.module.scss";

const HeaderNoAuth = () => {
    return (
        <Navbar className={`${s.root} d-print-none`}>
            <header className={l.logo}>
                <BolsaComercioLogo height={"60px"}/>
            </header>
        </Navbar>
    )
}

export default HeaderNoAuth