import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Alert } from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import s from "./ReportesNoContratados.module.scss";
import ModalDetalle from '../../components/Reportes/ModalDetalle.js';
import api from '../../api/api.js';
import { setIsLoading } from '../../actions/actions.js';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import l from '../../styles/Notificacion.scss'
import { AccionesEnum } from "../../util/enums";

const URL_CONTRATACION = process.env.REACT_APP_URL_CONTRATACION

const ReportesNoContratados = () => {
    const dispatch = useDispatch()
    const [reporteNoContratadoSeleccionado, setReporteNoContratadoSeleccionado] = useState(null);
    const [reportes, setReportes] = useState(null);
    const handleClose = () => setReporteNoContratadoSeleccionado(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await api.getReportesNoContratados()

                setReportes(data)
            }
            catch {
                toast.error('Ha ocurrido un error al obtener reportes no contratados.')
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [])
    const registraTransaccion = async (IdReporte) => {
        const transaccion = {
            IdAccion: AccionesEnum.VER_REPORTE_NO_CONTRATADO.value,
            Contenido: { IdReporteNoContratado: IdReporte }
        };

        await api.guardarTransaccion(transaccion)

    }
    const onClicReporteNoContratado = (reporte) => {

        registraTransaccion(reporte.Id);
        //guardar transaccion
        setReporteNoContratadoSeleccionado(reporte)

    }

    const armarIndices = (reportes) => {
        let iteraciones = reportes?.length / 3
        let indices = []
        let aux = 0

        while (iteraciones > 0) {
            indices.push({ desde: aux, hasta: aux + 2 })
            iteraciones--
            aux = aux + 3
        }
        //ver cuando sea un numero impar ejemplo 11 reportes
        //que sean todos los reportes bien mostrados, comparar con lo publicadod

        return <>
            {indices?.map((item, index) => {
                return <Row className="gutter mb-4">
                    {reportes?.slice(item.desde, item.hasta + 1).map((rep, indexRep) => {
                        return <Col xs={12} lg={4} key={indexRep}>
                            <Widget className="widget-p-md">
                                <div className="headline-2">{rep.Nombre}</div>
                                <div className={s.widgetText}><p className="body-3 text-muted textoParrafo">{rep.Descripcion}</p></div>
                                <div className={s.divImg}>
                                    <img alt='' src={process.env.PUBLIC_URL + `/img/${rep.Id}.png`} style={{ cursor: 'pointer' }} onClick={() => onClicReporteNoContratado(rep)} />
                                </div>
                                <div className="mt-3 mb-0 d-flex justify-content-center">
                                    <a href={URL_CONTRATACION}
                                        target="_blank"
                                        rel='noreferrer'
                                        className='btn btn-primary'>Contratar</a>
                                </div>
                            </Widget>
                        </Col>
                    })}
                </Row>
            })
            }
        </>
    }

    return (
        <div>
            <ModalDetalle
                show={reporteNoContratadoSeleccionado ? true : false}
                reporte={reporteNoContratadoSeleccionado}
                handleClose={handleClose}
            ></ModalDetalle>
            <Row className="gutter mb-4">
                <Col xs={12} lg={12}>
                    <Widget className="widget-p-md">
                        <div className="d-flex justify-content-between">
                            <div className="headline-3 d-flex align-items-center">
                                Listado de Reportes disponibles para ser contratados.</div>
                        </div>
                    </Widget>
                </Col>
            </Row>

            {reportes?.length === 0 ? <>
                <Row className="gutter mb-4">
                    <Col xs={12} lg={12}>
                        <Widget className="widget-p-md">
                            <Alert
                                style={{ backgroundColor: '#00A5FF' }}
                                className={`${l.notificationContainer} mb-1`}
                            >
                                No posee nuevos Reportes para contratar.
                            </Alert>
                        </Widget>
                    </Col>
                </Row>
            </> :
                <>
                    {armarIndices(reportes)}
                </>
                // <>
                // {reportes?.map((item, index) => {
                //     return <React.Fragment>
                //         {/* {(index + 1) % 3 == 0 ? <>{'<Row className="gutter mb-4">'}</> : null} */}
                //         {(index + 1) % 3 == 0 ? <>{Parser('<Row className="gutter mb-4">')}</> : null}


                //         <Col xs={12} lg={4} key={index}>
                //             <Widget className="widget-p-md">
                //                 <div className="headline-2">{item.Nombre}</div>
                //                 <div className={s.widgetText}><p className="body-3 text-muted textoParrafo">{item.Descripcion}</p></div>
                //                 <div className={s.divImg}>
                //                     {/* <img alt='' src={process.env.PUBLIC_URL + `/img/1.png`} style={{ cursor: 'pointer' }} onClick={() => setShow(true)} /> */}
                //                     <img alt='' src={process.env.PUBLIC_URL + `/img/${item.Id}.png`} style={{ cursor: 'pointer' }} onClick={() => onClicReporteNoContratado(item)} />
                //                 </div>
                //                 <div className="mt-3 mb-0 d-flex justify-content-center"><Button color="primary">Contratar</Button></div>
                //             </Widget>
                //         </Col>
                //         {index % 3 == 0 ? <>{Parser('</Row>')}</> : null}
                //     </React.Fragment>
                // })}
                // </>
            }

        </div >
    );
}


export default ReportesNoContratados;
