import React from "react";
import FooterIcon from "../Icons/FooterIcon";
import s from "./Footer.module.scss";
import LogoBolsa from "../../assets/LogoBolsaORIGINAL.png"
import { Col, Row } from "reactstrap";

const Footer = () => {
  return (
    <div className={s.footer}>
      <Row className="justify-content-end">
        <Col xs={6} sm={4} md={1} >
          <img className="img-fluid" src={LogoBolsa} alt="Logo Bolsa Comercio Santiago" />
        </Col>
      </Row>
    </div>
  )
}

export default Footer;
