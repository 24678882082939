import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import s from "../../pages/MisReportes/ReportesNoContratados.module.scss";
import api from "../../api/api";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

const ModalDetalle = ({ show, handleClose, reporte }) => {

    const onDescargarManual = async () => {
        api.getManualReporte(reporte?.Id).then(
            (response) => {
                var blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                });
                saveAs(blob, `manual_${reporte?.Nombre}.pdf`);
            }
        ).catch(e => {
            toast.error("Ocurrio un error al descargar el manual.")
        })
    }

    return (
        <Modal isOpen={show} toggle={handleClose} size='xl'>
            <ModalHeader toggle={handleClose}>Detalle de reporte</ModalHeader>
            <ModalBody>
                <Row className="gutter mb-4">
                    <Col xs={12} lg={12}>
                        <div className={s.divImg}>
                            <img alt='' src={process.env.PUBLIC_URL + `/img/${reporte?.Id}.png`} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button
                            color="primary"
                            onClick={() => onDescargarManual()}>
                            <i className="fa fa-file" style={{ fontSize: "20px" }} />{' '}<span>Manual</span>
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => handleClose()}>Aceptar</Button>{' '}
            </ModalFooter>
        </Modal>
    );
}

export default ModalDetalle