import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import api from "../../api/api";
import ReCAPTCHA from "react-google-recaptcha";
const claveSitio = process.env.REACT_APP_CAPTCHA_SITE_KEY

const VisorReporteNoAuth = () => {

    // const [reportEmbed, setReportEmbed] = useState(null);
    const [reportConf, setReportConf] = useState(null)
    const isMobile = window.innerWidth <= process.env.REACT_APP_MOBILE_BREAK_WIDTH;
    const recaptchaRef = React.useRef();

    useEffect(() => {
        const fetchData = async () => {

            const captchaToken = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            const reportConfig = await api.getReporteDemo(captchaToken)

            setReportConf(reportConfig);
        }

        fetchData()
    }, [])


    return (
        <>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={claveSitio}
            />
            <PowerBIEmbed
                embedConfig={{
                    type: "report",
                    id: reportConf?.EmbedReport.ReportId,
                    embedUrl: reportConf?.EmbedReport.EmbedUrl,
                    accessToken: reportConf?.EmbedToken.Token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                            pageNavigation: {
                                visible: false
                            }
                        },
                        layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
                        background: models.BackgroundType.Transparent,
                        customLayout: {
                            pageSize: {
                                type: models.PageSizeType.Custom,
                                width: "100%",
                                height: "100%",
                            },
                            displayOption: models.DisplayOption.ActualSize,
                        },
                    },
                }}
                eventHandlers={
                    new Map([
                        [
                            "loaded",
                            function () {
                                console.log("Report loaded");
                            },
                        ],
                        [
                            "rendered",
                            function () {
                                console.log("Report rendered");
                            },
                        ],
                        [
                            "error",
                            function (event) {
                                console.log(event.detail);
                            },
                        ],
                    ])
                }
                cssClassName={"report-style-class"}
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                    // setReportEmbed(embeddedReport);
                }}
            />
        </>
    );
}


export default VisorReporteNoAuth
