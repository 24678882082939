const initialState = {
    idReporte: null,
    datosUsuario: null,
    isLoading: false
}


const fetchIsLoading = (state, { payload }) => {
    return {
        ...state,
        isLoading: payload,
    }
}

const fetchIdReporte = (state, { payload }) => {
    return {
        ...state,
        idReporte: payload,
    }
}

const fetchDatosUsuario = (state, { payload }) => {
    return {
        ...state,
        datosUsuario: payload,
    }
}

const actionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ID_REPORTE':
            return fetchIdReporte(state, action)
        case 'SET_INFO_USUARIO':
            return fetchDatosUsuario(state, action)
        case 'SET_IS_LOADING':
            return fetchIsLoading(state, action)
        default:
            return state;
    }
};

export default actionReducer