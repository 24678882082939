import React from "react";
import { useHistory } from 'react-router-dom'
import {
    Col,
    Row,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { connect } from "react-redux";
import { Switch, useRouteMatch, withRouter, Route, useParams } from "react-router";
import ListadoReporte from "./ListadoReporte.js";
import VisorReporte from "./VisorReporte.js";



const MisReportes = () => {
    const { idReporte } = useParams();
    const routeMatch = useRouteMatch();



    return (
        <>
            <Switch>
                <Route exact path={`${routeMatch.path}/:idReporte`}>
                    <Row>
                        <Col xs={12} md={12}>
                          
                        </Col>
                        <Col xs={12} md={12}>
                            <Widget className="widget-p-md">
                                <VisorReporte IdReporte={idReporte} />
                            </Widget>
                        </Col>
                    </Row>

                </Route>
                <Route exact path={`${routeMatch.path}`}>
                    <ListadoReporte />
                </Route>
            </Switch>
        </>
    );
}


export default withRouter(connect()(MisReportes));
// export default MisReportes;
