import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import * as dayjs from 'dayjs'
import * as timezone from 'dayjs/plugin/timezone';

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { closeSidebar, openSidebar } from "../../actions/navigation";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import warningIcon from '../Icons/HeaderIcons/icons-error.png'
import userImg2 from "../../assets/icons-user.png";
import ModalPerfil from "./ModalPerfil";
import ModalChangePassword from "./ChangePassword";
import s from "./Header.module.scss";
import "animate.css";
import BolsaComercioLogo from "../Icons/BolsaComercioLogo";
import l from "../Sidebar/Sidebar.module.scss";
import { API_ACCESS_TOKEN_KEY } from '../../config/AppConstants';

const Header = (props) => {
  const diasNotificacion = process.env.REACT_APP_DIAS_NOTIFICACION
  const { instance, accounts } = useMsal();
  const [notificaciones, setNotificaciones] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const datosUsuarios = useSelector(store => store.actionReducer.datosUsuario)
  const [showModalProfile, setShowModalProfile] = useState(false)
  const [showModalChangePassword, setShowModalChangePassword] = useState(false)
  const handleClose = () => setShowModalProfile(false)
  const history = useHistory();

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      const paths = props.location.pathname.split('/');
      paths.pop();
      props.dispatch(openSidebar());
    }
  }

  const doLogout = (instance, acounts) => {

    const accountTologOut = instance.getAccountByHomeId(acounts[0].homeAccountId)
    localStorage.removeItem(API_ACCESS_TOKEN_KEY)
    const logoutRequest = {
      account: accountTologOut,
      postLogoutRedirectUri: '/#/login',
    }

    instance.logoutRedirect(logoutRequest)
  }

  useEffect(() => {
    const fetchData = () => {
      dayjs.extend(timezone);
      dayjs.tz.setDefault("America/Santiago");
      const fechaActual = dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
      let fechaCaducidad = null

      if (datosUsuarios?.FechaCaducidad) {
        fechaCaducidad = dayjs(datosUsuarios.FechaCaducidad).diff(fechaActual, 'day')
      }
      setNotificaciones(fechaCaducidad)
    }
    fetchData()
  }, [datosUsuarios])

  return (
    <Navbar className={`${s.root} d-print-none`}>

      <ModalPerfil
        show={showModalProfile}
        setShow={setShowModalProfile}
        handleClose={handleClose}
        datosUsuarios={datosUsuarios}
      ></ModalPerfil>
      <ModalChangePassword
        show={showModalChangePassword}
        setShow={setShowModalChangePassword}
        handleClose={() => setShowModalChangePassword(false)}
      ></ModalChangePassword>
      <header className={l.logo} onClick={()=>history.push("/")}>
        <BolsaComercioLogo  height={"80px"}/>
      </header>
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`mr-3 ${s.navItem}`}
          href="#"
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div>
      <Nav className="ml-auto">
        <Dropdown nav isOpen={menuOpen} toggle={() => toggleMenu()} className="tutorial-dropdown mr-2 mr-sm-3">
          <DropdownToggle nav>
            <div className={s.navbarBlock}>
              <i className={'eva eva-bell-outline'} />
              {notificaciones != null ? <div className={s.count}></div> : ''}
            </div>
          </DropdownToggle>
          <DropdownMenu right className="navbar-dropdown notifications-dropdown">
            {notificaciones != null && notificaciones > 0 ? <>
              <DropdownItem><img src={warningIcon} alt="Envelope Icon" /><span >{notificaciones > 0 ? `Su cuenta caduca en ${notificaciones} ${notificaciones > 1 ? 'días' : 'día'}.` : 'Su cuenta caduca hoy.'}</span></DropdownItem>
            </> : notificaciones < 0 ?
              <><DropdownItem><img src={warningIcon} alt="Envelope Icon" /><span >Su cuenta ha caducado.</span></DropdownItem></>
              : <p>No posee nuevas notificaciones.</p>}
          </DropdownMenu>
        </Dropdown>
        <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
          <DropdownToggle nav caret className="navbar-dropdown-toggle">
            <span className={`${s.avatar} rounded-circle float-left mr-2`}>
              <img src={userImg2} alt="User" />
            </span>
            <span style={{ fontFamily: 'Avenir' }} className="small d-none d-sm-block ml-1 mr-2 body-1">
              {datosUsuarios?.Nombres}{' '}{datosUsuarios?.Apellidos}
              <br></br>
              {datosUsuarios?.Entidad?.Tipo}{": "}{datosUsuarios?.Entidad?.Nombre}
            </span>
          </DropdownToggle>
          <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
            <DropdownItem className={s.dropdownProfileItem} onClick={() => setShowModalProfile(true)} ><ProfileIcon /><span className="menu-items">Perfil</span></DropdownItem>
            <DropdownItem className={s.dropdownProfileItem} onClick={() => setShowModalChangePassword(true)}><i className="eva eva-lock"></i><span className="menu-items">Contraseña</span></DropdownItem>
            {/* <DropdownItem className={s.dropdownProfileItem} onClick={() => window.open(`https://passwordreset.microsoftonline.com/?username=${datosUsuarios?.Email}`, "_blank")} ><ProfileIcon /><span className="menu-items">Cambiar contraseña</span></DropdownItem> */}
            <NavItem>
              <NavLink onClick={() => doLogout(instance, accounts)} href="#">
                <button className="btn btn-primary-bolsa rounded-pill mx-auto logout-btn mt-1" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

