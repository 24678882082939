import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, FormGroup, FormText, Input } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import TwitterIcon from "../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../components/Icons/AuthIcons/FacebookIcon.js";
import LinkedinIcon from "../../components/Icons/AuthIcons/LinkedinIcon.js";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { setIsLoading } from "../../actions/actions";
import { useForm, Controller } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { ExisteEmailRegistrado } from "../../services/userService";
import Loader from "../../components/Spinner/Loading"
import { toast } from 'react-toastify'
import BolsaComercioLogo from "../../components/Icons/BolsaComercioLogo.js";

const claveSitio = process.env.REACT_APP_CAPTCHA_SITE_KEY
const Login = (props) => {

  const recaptchaRef = React.useRef();

  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const estaCargando = useSelector(store => store.actionReducer.isLoading)

  const { control, getValues, setError, handleSubmit, formState: { errors } } = useForm();

  const handleLogin = async () => {

    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();


      // e.preventDefault();
      dispatch(setIsLoading(true))
      let emailUsuario = getValues("email")

      let res = await ExisteEmailRegistrado(emailUsuario, captchaToken)
      dispatch(setIsLoading(false))
      
      if (res && res.fueCorrecto) {
        await instance.loginRedirect({ ...loginRequest, loginHint: emailUsuario }).catch(e => {
          throw e;
        });
      } else {
        setError('email', { type: 'custom', message: 'Usuario no autorizado.' });
      }
    }
    catch {
      toast.error('Ha ocurrido un error, inténtelo nuevamente.')
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }

  const { from } = props.location.state || { from: { pathname: '/bolsaComercio' } };

  if (isAuthenticated) {
    return (
      <Redirect to={from} />
    )
  }

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <div className="auth-page">
      {estaCargando ? <Loader></Loader> : null}
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={12} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-center">
                <div className="logo-block">
                  <BolsaComercioLogo />
                </div>
              </div>
              <form onSubmit={handleSubmit(handleLogin)}>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={claveSitio}
                />
                <br></br>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true, placeHolder: "Email", pattern: emailRegex }}
                    render={({ field }) =>
                      <Input {...field} />
                    }
                  />
                  {errors.email &&
                    errors.email.type === "required" && (
                      <span id="valid">Este campo es obligatorio.</span>
                    )}
                  {errors.email &&
                    errors.email.type === "pattern" && (
                      <span id="valid">Ingrese un email válido.</span>
                    )}
                  {errors.email &&
                    errors.email.type === "custom" && (
                      <span id="valid">{errors.email.message}</span>
                    )}
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <button
                    className="btn btn-primary-bolsa rounded-pill my-3"
                    // onClick={(e) => handleLogin(e, instance)}
                    type="submit">Login</button>
                </div>
                {/* <FormGroup>
                  <div >
                    <Link to="/Demo">¿No tienes una cuenta?, Revisa nuestra Demo</Link>
                  </div>
                </FormGroup> */}
                <p className="dividing-line my-3">&#8195;Redes sociales&#8195;</p>
                <div className="d-flex justify-content-center">
                  <div className="socials">
                    <a target="_blank" href="https://www.bolsadesantiago.com/" rel="noreferrer"><i className="eva eva-globe"></i></a>
                    <a target="_blank" href="https://www.bolsadesantiago.com/productos" rel="noreferrer"><i className="eva eva-monitor"></i></a>
                    <a target="_blank" href=" https://www.linkedin.com/company/bolsadesantiago/" rel="noreferrer" ><LinkedinIcon /></a>
                    <a target="_blank" href="https://twitter.com/bolsadesantiago?lang=es" rel="noreferrer" ><TwitterIcon /></a>
                  </div>
                </div>
              </form>
            </Widget>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </div>
  )
}


Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {

  };
}

export default withRouter(connect(mapStateToProps)(Login));
