import config from './configAxios'

const axios = config.iAxios;

const exportarReporte = async (formato, estado, filtros, workspaceId, reportId) => {
    return axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/ExportTo`,
        {
            format: formato,
            powerBIReportConfiguration: {
                settings: {
                    locale: "es-es"
                },
                defaultBookmark: {
                    state: estado.state
                },
                // reportLevelFilters: {
                //     filter: filtros
                // }
            }
        }).then((res) => { return res.data })
}

const getStatusExportacion = async (workspaceId, reportId, idExportacion) => {
    return axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/exports/${idExportacion}`).then((res) => { return res.data })
}

const getFileExport = async (workspaceId, reportId, idExportacion) => {
    return axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/exports/${idExportacion}/file`,
        {
            responseType: 'arraybuffer',
            'Content-Type:': 'application/*'
        }
    ).then((res) => { return res })
}

export default {
    exportarReporte,
    getStatusExportacion,
    getFileExport
}