import React, { useState } from "react";
import {
    Col,
    Row,
    InputGroupAddon,
    Input,
    InputGroup,
    FormGroup,
    Form
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import Seccion from "../../components/Reportes/Seccion.js";
import s from './MisReportes.module.scss'
import SearchBarIcon from "../../components/Icons/HeaderIcons/SearchBarIcon.js";

const ListadoReporte = () => {
    const datosUsuarios = useSelector(store => store.actionReducer.datosUsuario)
    const [reportes, setReportes] = useState(datosUsuarios?.Reportes)

    const crearCollapsesSecciones = () => {
        const opciones = reportes || datosUsuarios?.Reportes

        const secciones = [...new Map(opciones?.map(item => [item.Seccion, item.Seccion])).values()];

        return secciones.map((seccion, index) =>

            <div key={index}>
                <Seccion seccion={seccion} cantidad={opciones?.filter(x => x.Seccion == seccion).length} reportes={opciones}></Seccion>
            </div>
        )
    }

    const filtrarReportes = (value) => {
        if (value.length > 0) {
            const fitrado = datosUsuarios?.Reportes?.filter(x => x.Nombre.toLowerCase().includes(value.toLowerCase()) || x.Descripcion.toLowerCase().includes(value.toLowerCase()))

            setReportes(fitrado)
        }
        else {
            setReportes(datosUsuarios?.Reportes)
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} md={12}>
                    <Widget className="widget-p-md">
                        {/* 2 secciones para diferentes despliegues segun el viewport */}
                        <div className="d-none d-sm-block">
                            <div className="d-flex justify-content-between">
                                <div className="headline-3 d-flex align-items-center">Mis Reportes</div>
                                <Form className="d-none d-sm-block" inline>
                                    <FormGroup>
                                        <InputGroup className='input-group-no-border'>
                                            <Input id="search-input" placeholder="Buscar" className='focus' onChange={e => filtrarReportes(e.target.value)} />
                                            <InputGroupAddon addonType="prepend">
                                                <span className="mt-2 ml-2">
                                                    <SearchBarIcon />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                        <div className="d-block d-sm-none">
                            <div className="d-flex-row justify-content-between">
                                <div className="headline-3 d-flex align-items-center">Mis Reportes</div>
                                <Form className="d-block d-sm-none" inline>
                                    <FormGroup>
                                        <InputGroup className='input-group-no-border'>
                                            <Input id="search-input" placeholder="Buscar" className='focus' onChange={e => filtrarReportes(e.target.value)} />
                                            <InputGroupAddon addonType="prepend">
                                                <span className="mt-2 ml-2">
                                                    <SearchBarIcon />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>

                        {crearCollapsesSecciones()}
                    </Widget>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(connect()(ListadoReporte));