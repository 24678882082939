import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import s from '../../styles/Notificacion.scss'
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router";
import api from "../../api/api";
import { saveAs } from "file-saver";
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { Alert, Row, Col } from "reactstrap";
import { setIsLoading } from "../../actions/actions";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import ModalExportar from "../../components/Reportes/ModalExportar";
import { AccionesEnum } from '../../util/enums'
import { openSidebar } from "../../actions/navigation";
import { toast } from "react-toastify";
import { closeSidebar } from "../../actions/navigation";

const VisorReporte = () => {
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch()
    const reportesUsuario = useSelector(store => store.actionReducer.datosUsuario?.Reportes)
    const { idReporte } = useParams()
    const [tienePermiso, setTienePermiso] = useState(null)
    const [disabledExport, setDisabledExport] = useState(true)
    const [reportEmbed, setReportEmbed] = useState(null);
    const [reportConf, setReportConf] = useState(null)
    const isMobile = window.innerWidth <= process.env.REACT_APP_MOBILE_BREAK_WIDTH;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const history = useHistory();

    useEffect(() => {
        dispatch(openSidebar())
    }, [])


    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    const registraTransaccion = async (IdReporte) => {
        const transaccion = {
            IdAccion: AccionesEnum.VER_REPORTE.value,
            Contenido: {
                IdReporte: IdReporte
            }
        };
        await api.guardarTransaccion(transaccion)
    }
    const volverAMisReportes = () => {
        dispatch(closeSidebar());
        history.push('/reportes')
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const puedeVer = reportesUsuario?.some(x => x.Id == idReporte)

                if (puedeVer) {

                    const reporte = await api.getReporte(idReporte)

                    instance.acquireTokenSilent(request).then((response) => {
                        setReportConf({
                            id: reporte.ReportId,
                            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reporte.ReportId}&groupId=${reporte.WorkSpaceId}`,
                            token: response.accessToken,
                            workspaceId: reporte.WorkSpaceId,
                            nombre: reporte.Nombre
                        })

                    }).catch((e) => {
                    });

                    await registraTransaccion(idReporte)
                }
                setTienePermiso(puedeVer)
            }
            catch {
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [idReporte, reportesUsuario])


    const onExportar = () => {
        setShow(true)
    }

    const onDescargarManual = async () => {
        api.getManualReporte(idReporte).then(
            (response) => {
                var blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                });
                saveAs(blob, `manual_${reportConf?.nombre}.pdf`);
            }
        ).catch(e => {
            toast.error("Ocurrio un error al descargar el manual.")
        })
    }


    return (
        <>
            <ModalExportar
                show={show}
                handleClose={handleClose}
                reportId={reportConf?.id}
                workspaceId={reportConf?.workspaceId}
                reportEmbed={reportEmbed}
                nombre={reportConf?.nombre}
            ></ModalExportar>

            {tienePermiso == false ? <>
                <Alert
                    style={{ backgroundColor: '#FF4B23' }}
                    className={`${s.notificationContainer} mb-1`}
                >
                    No posee permisos para visualizar este Reporte.
                </Alert>
            </> :
                <>
                    <Row className="d-md-none">
                        <Col xs={12} sm={12} md={12} className="mb-2">
                            <Button
                                block
                                color="primary"
                                className="mx-1"
                                onClick={() => volverAMisReportes()}>
                                Volver
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} className="mb-2">
                            <Button
                                block
                                color="primary"
                                className="mx-1"
                                disabled={disabledExport}
                                onClick={() => onExportar()}>Exportar Reporte
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} className="mb-2">
                            <Button
                                block
                                color="primary"
                                className="mx-1"
                                disabled={disabledExport}
                                onClick={() => onDescargarManual()}>
                                <i className="fa fa-file" style={{ fontSize: "20px" }} />{' '}<span>Manual</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-none d-md-block">
                        <Col xs={12} sm={12} md={12} className="mb-2 btn-group">
                            <Button
                                color="primary"
                                className="mx-1"
                                onClick={() => volverAMisReportes()}>
                                Volver
                            </Button>
                            <Button
                                color="primary"
                                className="mx-1"
                                disabled={disabledExport}
                                onClick={() => onExportar()}>Exportar Reporte
                            </Button>
                            <Button
                                color="primary"
                                className="mx-1"
                                disabled={disabledExport}
                                onClick={() => onDescargarManual()}>
                                <i className="fa fa-file" style={{ fontSize: "20px" }} />{' '}<span>Manual</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <PowerBIEmbed
                                embedConfig={{
                                    type: "report",
                                    id: reportConf?.reportId,
                                    embedUrl: reportConf?.embedUrl,
                                    accessToken: reportConf?.token,
                                    tokenType: models.TokenType.Aad,
                                    settings: {
                                        panes: {
                                            filters: {
                                                expanded: false,
                                                visible: false,
                                            },
                                            pageNavigation: {
                                                visible: false
                                            }
                                        },
                                        layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
                                        background: models.BackgroundType.Transparent,
                                        customLayout: {
                                            pageSize: {
                                                type: models.PageSizeType.Custom,
                                                width: "100%",
                                                height: "100%"
                                            },
                                            displayOption: models.DisplayOption.ActualSize,
                                        },
                                    },
                                }}
                                eventHandlers={
                                    new Map([
                                        [
                                            "loaded",
                                            function () {
                                                console.log("Report loaded");
                                            },
                                        ],
                                        [
                                            "rendered",
                                            function () {
                                                setDisabledExport(!disabledExport)
                                                console.log("Report rendered");
                                            },
                                        ],
                                        [
                                            "error",
                                            function (event) {
                                                console.log(event.detail);
                                            },
                                        ],
                                    ])
                                }
                                cssClassName={"report-style-class"}
                                getEmbeddedComponent={(embeddedReport) => {
                                    window.report = embeddedReport;
                                    setReportEmbed(embeddedReport);
                                }}
                            />
                        </Col>
                    </Row>

                </>}
        </>
    );
}


export default withRouter(connect()(VisorReporte));
