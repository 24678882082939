import axiosAnonConfig from '../api/configAxiosAnon'
import axiosGraphConfig from '../api/configAxiosGraph'

const axiosAnon = axiosAnonConfig.iAxios
const axiosJwtGraph = axiosGraphConfig.iAxios
const urlApi = process.env.REACT_APP_URLAPI

//#region llamadas anonimas
export const ExisteEmailRegistrado = async (emailUsuarioBuscar, captchaToken) => {
    return axiosAnon.get(`${urlApi}/api/Usuario/${emailUsuarioBuscar}/existe/${captchaToken}`)
        .then((res) => { return res.data })
}
//#endregion


//region llamadas graph
export const CambiarContraseña = async (data) => {
    return axiosJwtGraph.post(`${urlApi}/api/graph/password`, data)
        .then((res) => { return res.data })
}
