import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { daFormatoRut } from '../../services/Util'

const ModalPerfil = ({ show, setShow, handleClose, datosUsuarios }) => {
    return (
        <Modal isOpen={show} toggle={handleClose} size='lg' centered={true}>
            <ModalHeader toggle={handleClose}>Perfil</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Nombres</strong></label>
                            <p>{datosUsuarios?.Nombres}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Apellidos</strong></label>
                            <p>{datosUsuarios?.Apellidos}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Rut</strong></label>
                            <p>{daFormatoRut(datosUsuarios?.Rut)}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Teléfono</strong></label>
                            <p>{datosUsuarios?.Telefono}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Tipo Institución</strong></label>
                            <p> {datosUsuarios?.Entidad?.Tipo}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Institución</strong></label>
                            <p>{datosUsuarios?.Entidad?.Nombre}</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Email</strong></label>
                            <p>{datosUsuarios?.Email}</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group detalle-finiquito">
                            <label className="mb-0"><strong>Usuario</strong></label>
                            <p>{datosUsuarios?.NombrePrincipalUsuario}</p>
                        </div>
                    </div>

                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => setShow(false)}>Aceptar</Button>{' '}
            </ModalFooter>
        </Modal>
    );
}

export default ModalPerfil