import React, { useState, useEffect } from "react";
import { saveAs } from 'file-saver';
import { useParams } from "react-router";
import { Progress, Row, Col } from 'reactstrap';
import { toast } from "react-toastify";
import api from "../../api/apiPowerBi.js";


const ExportarReporte = () => {
    const [progreso, setProgreso] = useState(5)
    const { id, workspaceId, reportId, nombre } = useParams();

    const [descargaCompletada, setDescargaCompletada] = useState(false);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }



    useEffect(() => {
        descargarArchivo()

    }, [id, workspaceId, reportId]);

    const descargarArchivo = async () => {
        try {
            let status = null
            let stautsLocal = 5
            do {
                await sleep(3000)
                status = await api.getStatusExportacion(workspaceId, reportId, id)

                if (status.percentComplete > stautsLocal) {
                    setProgreso(status.percentComplete === 100 ? 95 : status.percentComplete)
                }
                else if (stautsLocal < 95) {
                    const diferencia = 95 - stautsLocal
                    stautsLocal = diferencia <= 5 ? stautsLocal + diferencia : stautsLocal + 5

                    setProgreso(stautsLocal)
                }
            } while (status.status !== 'Succeeded' && status.status !== 'Failed')

            if (status.status === 'Succeeded') {
                const response = await api.getFileExport(workspaceId, reportId, id)
                var blob = new Blob([response.data], { type: response.headers["content-type"] })

                saveAs(blob, `${nombre}`);
                setProgreso(100)
                setDescargaCompletada(true)
            } else {
                toast.error('Ha ocurrido un error al descargar archivo, inténtelo nuevamente.')
            }
        }
        catch (exception) { }
        finally {
            // window.close()
        }
    }

    return (<>
        <div>
            <Row>
                <Col xs={12} lg={12}>
                    <p className="mb-1 mt-2">
                        {descargaCompletada ? "Documento generado" : "Generando documento.."}

                    </p>
                    <Progress animated={descargaCompletada ? false : true} color="info" value={progreso}>{progreso}%</Progress>
                </Col>
            </Row>
        </div>
    </>)
}

export default ExportarReporte