import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup } from 'reactstrap';
import { useForm } from "react-hook-form";
import api from "../../api/apiPowerBi.js";
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import { setIsLoading } from "../../actions/actions";

const ModalExportar = ({ show, handleClose, reportId, workspaceId, reportEmbed, nombre }) => {
    const dispatch = useDispatch()

    const { register, handleSubmit } = useForm({
        defaultValues: {
            tipoExportacion: 'PDF'
        }
    });

    const onSubmit = async (formato) => {
        try {
            dispatch(setIsLoading(true))
            const estado = await reportEmbed.bookmarksManager.capture()
            const filtros = await reportEmbed.getFilters()
            const generacion = await api.exportarReporte(formato.tipoExportacion, estado, filtros, workspaceId, reportId)

            if (generacion?.id) {
                window.open(`/#/exportar/${generacion.id}/${workspaceId}/${reportId}/${nombre}`);
                handleClose()
            }
            else {
                toast.error('Ha ocurrido un error al descargar archivo, inténtelo nuevamente.')
            }
        }
        catch (exception) {
            console.log(exception)
        }
        finally {
            dispatch(setIsLoading(false))
        }
    }

    return (
        <Modal isOpen={show} toggle={handleClose} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={handleClose}>Seleccione formato de descarga</ModalHeader>
                <ModalBody>
                    <FormGroup tag="fieldset">
                        <FormGroup check>
                            <input
                                {...register("tipoExportacion")}
                                name="tipoExportacion"
                                type="radio"
                                value={'PDF'}
                            />
                            {' '}
                            <Label check>
                                PDF
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <input
                                {...register("tipoExportacion")}
                                name="tipoExportacion"
                                type="radio"
                                value={'PPTX'}
                            />
                            {' '}
                            <Label check>
                                Presentación de power Point
                            </Label>
                        </FormGroup>
                        {/* Se remueve opción de descarga de PNG por permisos no habilitados  */}
                        {/* <FormGroup
                            check
                        >
                            <input
                                {...register("tipoExportacion")}
                                name="tipoExportacion"
                                type="radio"
                                value={'PNG'}
                            />
                            {' '}
                            <Label check>
                                Imagen PNG
                            </Label>
                        </FormGroup> */}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type='submit'>Aceptar</Button>{' '}
                    <Button color="secondary" onClick={handleClose}>Cancelar</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ModalExportar