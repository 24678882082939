export function esRutValido(rut) {
    if (typeof rut !== "string") {
        return false;
    }
    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false;
    }

    rut = clean(rut);

    var t = parseInt(rut.slice(0, -1), 10);
    var m = 0;
    var s = 1;

    while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
    }

    var v = s > 0 ? "" + (s - 1) : "K";
    return v === rut.slice(-1);
}

export function clean(rut) {
    return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
}

export const allowOnlyNumber=(value)=>{
    return value.replace(/[^0-9]/g, '')
 }

 export function daFormatoRut(rutDni) {
    if (esRutValido(rutDni)) {
        rutDni = clean(rutDni);

        var result = rutDni.slice(-4, -1) + "-" + rutDni.substr(rutDni.length - 1);
        for (var i = 4; i < rutDni.length; i += 3) {
            result = rutDni.slice(-3 - i, -i) + "." + result;
        }
        return result;
    } else {
        return rutDni;
    }
}