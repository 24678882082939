import React from "react";
import HeaderNoAuth from "../../components/Header/HeaderNoAuth";
import Footer from "../../components/Footer/Footer";
import Widget from "../../components/Widget/Widget";
import { Row, Col, Button } from "reactstrap";
import VisorReporteNoAuth from "../MisReportes/VisorReporteNoAuth";
import s from '../../components/Layout/Layout.module.scss'
import { useHistory } from 'react-router-dom';


const Demo = () => {
    const history = useHistory();
    return (
        <>
            <div className={s.root}>
                <HeaderNoAuth />
                <div className={`${s.wrap} ${s.colapsar}`}>
                    <main className={s.content}>
                        <Widget className="widget-p-md">
                            <Row className="gutter ml-2 mr-2 mt-3">
                                <Col xs={12} lg={12}>
                                    <div className="d-flex justify-content-between mb-4">
                                        <div className="headline-3 d-flex align-items-center">
                                            <p className="tituloParrafo"> Lorem ipsum dolor sit amet consectetur adipiscing elit netus platea, consequat inceptos nunc rutrum velit ad.</p></div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="headline-3 d-flex align-items-center">
                                            <p className="body-3 text-muted textoParrafo">
                                                Lorem ipsum dolor sit amet consectetur adipiscing elit netus platea, consequat inceptos nunc rutrum velit ad risus quis nibh, rhoncus metus euismod purus odio ac nisl donec. Vestibulum morbi nibh tortor hac metus cum consequat vivamus enim interdum litora, convallis potenti at viverra sollicitudin mi nec dapibus per himenaeos, nisl sagittis sociosqu non in integer imperdiet senectus gravida mattis. Parturient senectus dapibus fames ultricies cras porta nostra ultrices ornare vulputate, tempor nibh scelerisque sed phasellus mus nascetur natoque orci curabitur, integer ad habitasse quis convallis neque diam dictumst pharetra.
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-between">
                                        <div className="headline-3 d-flex align-items-center">
                                            <p className="body-3 text-muted textoParrafo">
                                                Lorem ipsum dolor sit amet consectetur adipiscing elit netus platea, consequat inceptos nunc rutrum velit ad risus quis nibh, rhoncus metus euismod purus odio ac nisl donec. Vestibulum morbi nibh tortor hac metus cum consequat vivamus enim interdum litora, convallis potenti at viverra sollicitudin mi nec dapibus per himenaeos, nisl sagittis sociosqu non in integer imperdiet senectus gravida mattis. Parturient senectus dapibus fames ultricies cras porta nostra ultrices ornare vulputate, tempor nibh scelerisque sed phasellus mus nascetur natoque orci curabitur, integer ad habitasse quis convallis neque diam dictumst pharetra.
                                            </p>
                                        </div>
                                    </div> */}
                                </Col>
                                <Col xs={12} lg={12}>
                                    <VisorReporteNoAuth></VisorReporteNoAuth>
                                </Col>
                            </Row>
                            <Row className="gutter ml-2 mr-2 mt-3">
                                <Col xs={12} lg={12}>
                                    <Button color="primary" onClick={() => history.push("/Login")}>Volver</Button>
                                </Col>
                            </Row>
                        </Widget>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Demo