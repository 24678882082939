import axios from 'axios'

let iAxios = axios.create({
    defaultInterceptors: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
})

iAxios.interceptors.request.use(
    async (config) => {
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

iAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.log(error)
        return Promise.reject(error)
    }
)

const config = { iAxios }

export default config