// -- React and related libs
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import { useSelector } from "react-redux";
import Loader from "../Spinner/Loading";
// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import MisReportes from "../../pages/MisReportes/MisReportes";
import ReportesNoContratados from "../../pages/MisReportes/ReportesNoContratados";
import ExportarReporte from "../../pages/reporte/ExportarReporte";
import { getInfoUsuario, setInfoUsuario } from "../../actions/actions.js";
import { useIsAuthenticated } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { API_ACCESS_TOKEN_KEY } from '../../config/AppConstants';

// -- Component Styles
import s from "./Layout.module.scss";

const Layout = (props) => {
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()
  const colapsar = useSelector(store => store.navigation.sidebarOpened)

  const estaCargando = useSelector(store => store.actionReducer.isLoading)

  // const [tokenCargado, setTokenCargado] = useState(false);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const token = localStorage.getItem(API_ACCESS_TOKEN_KEY);
  //     console.log("Token el local storage: " + token);

  //     if (token) {
  //       setTokenCargado(true);
  //       clearInterval(intervalId);
  //       dispatch(getInfoUsuario())
  //     }


  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        dispatch(getInfoUsuario())
      }
      else {
        dispatch(setInfoUsuario(null))
      }
    }
    fetchData()
  }, [isAuthenticated])

  return (
    <div className={s.root}>

      {estaCargando ? <Loader></Loader> : null}
      <Header />
      <div className={`${s.wrap} ${colapsar ? `${s.colapsar}` : ""}`}>
        <Sidebar />

        <main className={s.content}>
          <Switch>
            <Route path="/login" exact render={() => <Redirect to="/reportes" />} />
            <Route path="/reportes" component={MisReportes} />
            <Route path="/reportesNoContratados" component={ReportesNoContratados} />
            <Route path="/Exportar/:id/:workspaceId/:reportId/:nombre" exact component={ExportarReporte} />
            <Route path="/" exact render={() => <Redirect to="/reportes" />} />
            <Route path='*' exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>

        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
