import { useSelector } from "react-redux";
import { Collapse, Row, Col } from "reactstrap";
import { useState } from "react";
import s from '../../pages/MisReportes/MisReportes.module.scss'
import { Link } from "react-router-dom";
import { useRouteMatch, withRouter } from "react-router";

const Seccion = ({ seccion, cantidad, reportes }) => {
    const [collapse, setCollapse] = useState(false);
    const datosUsuarios = useSelector(store => store.actionReducer.datosUsuario)
    const routeMatch = useRouteMatch();

    return (
        <>
            <div className={`mt-4 ${s.widgetBlock}`} >
                <div className={s.widgetBody}>
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            <a style={{ textDecoration: 'underline' }} onClick={() => setCollapse(!collapse)} className="body-2">{seccion} ({cantidad})</a>
                        </div>
                    </div>
                </div>
            </div>

            <Collapse isOpen={collapse}>
                <div className="mt-3 pl-3 pr-3 pb-4  border rounded">
                    {reportes?.filter(x => x.Seccion === seccion)?.map((item, index) => {
                        return <div className={`mt-4  ${s.widgetBlock}`} key={index}>
                            <div className={s.widgetBody}>
                                <Row>
                                    <Col xs={12} sm={12} xl={12} >
                                        <div className="d-flex">
                                            <div className="" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                                                <h5>{item.Nombre}</h5>
                                                <p className="body-2">{item.Descripcion}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} xl={12}>
                                        <div className="body-3 muted mt-2">
                                            <Link to={`${routeMatch.url}/${item.Id}`}>
                                                Ver
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    })}
                </div>
            </Collapse>
        </>
    );
}

export default Seccion