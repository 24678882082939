import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import cn from "classnames";

const Sidebar = (props) => {


  const [subMenuMisReportes, setSubMenuMisReportes] = useState(null)
  const datosUsuarios = useSelector(store => store.actionReducer.datosUsuario)

  useEffect(() => {
    if (datosUsuarios && datosUsuarios.Reportes) {
      setSubMenuMisReportes(datosUsuarios.Reportes.map(item => {
        return {
          header: item.Nombre,
          link: `/reportes/${item.Id}`
        }
      }))
    }
  }, [datosUsuarios])

  const {
    activeItem = '',
    ...restProps
  } = props;

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0);
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })} >
      <ul className={s.nav}>
        <h5 className={s.navTitle}>Menú</h5>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Mis Reportes"
          isHeader
          iconName={<i className={'eva eva-archive-outline'} />}
          link="/reportes"
          index="reportes"
          childrenLinks={subMenuMisReportes}
        />

        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Reportes no contratados"
          isHeader
          iconName={<i className={'eva eva-archive-outline'} />}
          link="/reportesNoContratados"
          index="reportesNoContratados"
        />
      </ul>
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
