// -- React and related libs
import React from 'react';
import { render } from 'react-dom';

//azure
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../src/config/authConfig";
import { EventType } from "@azure/msal-browser";
// -- Redux
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import reducers from './reducers';

// -- App
import App from './App';
import { AccionesEnum } from "./util/enums";
import api from "./api/api";
// -- Service Worker
import * as serviceWorker from './serviceWorker';
import { loginRequest } from "./config/authConfig";


const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const msalInstance = new PublicClientApplication(msalConfig);



// -- Data Store
const store = createStore(
  reducers,
  composedEnhancers(applyMiddleware(ReduxThunk))
);

const registraTransaccionLogin = async () => {
  const transaccion = {
    IdAccion: AccionesEnum.LOGIN.value,
    Contenido: null
  };

  await api.guardarTransaccion(transaccion)

}
msalInstance.addEventCallback((event) => {
  // if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account) {
  //   localStorage.setItem(API_ACCESS_TOKEN_KEY, event.payload.accessToken)
  // }
  if ((event.eventType === EventType.LOGIN_SUCCESS) && event.payload.account) {
    console.log("Registrando login")
    registraTransaccionLogin()
  }

  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    console.log("Falla al obtener token")
    console.log(event.payload)
    // msalInstance.loginRedirect({ ...loginRequest, loginHint: emailUsuario }).catch(e => {
    //   console.error(e);
    // });

  }

})

// -- Rendering Application
render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

